import { START_LOCATION, createRouter, createWebHistory } from "vue-router";
import { authService } from "@/services/auth.service";
import Community from "@/views/PageCommunity.vue";
import Home from "@/views/PageHome.vue";
import About from "@/views/PageAbout.vue";
import NotFound from "@/views/PageNotFound.vue";
import Profile from "@/views/PageProfile.vue";
import { paths } from "@/utils/paths";
import PropositionsPage from "@/components/PropositionsPage.vue";
import ConsultationsPage from "@/views/PageConsultation.vue";
import { useCommunityStore } from "@/store/communityStore";
import { communitiesService } from "@/services/communities.service";
import CustomPage from "@/views/PageCustom.vue";
import { customPageService } from "@/services/customPage.service";
import { consultationsService } from "@/services/consultations.service";
import { constant } from "@/constant";
import ProjectPage from "@/views/PageProject.vue";
import { projectsService } from "@/services/projects.service";
import { useUserStore } from "@/store/userStore";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    routes: [
        {
            path: paths.root,
            name: "home",
            component: async () => {
                const communityStore = useCommunityStore();
                const community = communityStore.community;
                if (community.options.custom_homepage) {
                    return import("@/views/PageHome.vue");
                } else {
                    return import("@/views/PageCommunity.vue");
                }
            },
        },
        {
            path: paths.consultations_list,
            name: "consultations-list",
            component: Community,
        },
        {
            path: paths.login,
            name: "login",
            component: { template: '<div></div>' },
            beforeEnter: async (to, from, next) => {
                const token = to.query.token as string;
                await authService.loginUser(token);
                await consultationsService.getConsultations();
                let query = ""
                if (to.query.consultation) {
                    query = `consultations/${to.query.consultation as string}`;
                    if (to.query.section) {
                        query += `?section=${to.query.section as string}`;
                        if (to.query.form) {
                            query += `&form=${to.query.form as string}`
                        }
                    }
                } else if (to.query.projectId) {
                    const communityStore = useCommunityStore();
                    const userStore = useUserStore();
                    const projectId = parseInt(to.query.projectId as string);
                    const project = communityStore.getProject(projectId);
                    const result = communityStore.isUserAllowedAccessProject(project, userStore.user);
                    if (result.allowed) {
                        query = `projects/${project.slug}`;
                    } else {
                        query = `?projectId=${project.id}`;
                    }
                }
                next(`/${query}`);
            }
        },
        {
            path: paths.logout,
            name: "logout",
            component: { template: '<div></div>' },
            beforeEnter: async (to, from, next) => {
                await authService.logoutUser();
                next('/');
            }
        },
        {
            path: paths.consultations,
            name: "consultations",
            component: ConsultationsPage,
        },
        {
            path: paths.project,
            name: "project",
            component: ProjectPage,
        },
        {
            path: paths.propositions,
            name: "propositions",
            component: PropositionsPage,
        },
        {
            path: paths.about,
            name: "about-us",
            component: About,
        },
        {
            path: paths.profile,
            name: "profile",
            component: Profile,
        },
        {
            path: '/:lang(en|fr|es)',
            name: 'Language',
            component: Community,
        },
        {
            path: "/:slug(.*)", // wildcard route for custom pages
            name: "custom-page",
            component: CustomPage,
            beforeEnter: async (to, from, next) => {
                const communityStore = useCommunityStore();
                const slug = Array.isArray(to.params.slug) ? to.params.slug[0] : to.params.slug;
                const page = await communityStore.getCustomPageBySlug(slug);
                if (!page) {
                    console.debug("page not found");
                    next({ name: "not-found" });
                } else if (page.type === constant.Community.CustomPage.Redirect) {
                    next(page.content.en);
                }
                next();
            }
        },
        {
            path: "/not-found",
            name: "not-found",
            component: NotFound,
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    const communityStore = useCommunityStore();
    if (!communityStore.isLoaded) {
        const communitiesData: any = await communitiesService.getCommunities();
        await authService.initUser();
        if (communitiesData) {
            communityStore.setCommunity(communitiesData);
            await customPageService.getCustomPages();
            await consultationsService.getConsultations();
            await projectsService.fetchProjects();
        }
    }
    next();
});
export default router;
