import { Config } from '@/config';
import { currentLocale } from '@/plugins/i18n';
import apiService from '@/services/api.service';
import { useCommunityStore } from '@/store/communityStore';
import { useUserStore } from '@/store/userStore';

const accessTokenKey = 'accessToken';

export interface ConsultationOptions {
    consultationId: string;
    section?: number | null;
    form?: number | null;
};

export interface ProjectOptions {
    id: number;
};

export interface Options {
    consultation?: ConsultationOptions;
    project?: ProjectOptions;
};

class AuthService {
    async login(options?: Options) {
        const communityId = useCommunityStore()?.community?.id;
        const redirectUri = new URL(`${window.location.protocol}//${window.location.host}/login`);
        if (options?.consultation) {
            redirectUri.searchParams.append('consultation', options.consultation.consultationId);
            if (options.consultation.section) {
                redirectUri.searchParams.append('section', options.consultation.section.toString());
                if (options.consultation.form) {
                    redirectUri.searchParams.append('form', options.consultation.form.toString());
                }
            }
        } else if (options?.project) {
            redirectUri.searchParams.append('projectId', options.project.id.toString());
        }
        const params = new URLSearchParams({
            lang: currentLocale(),
            redirectUri: redirectUri.toString(),
        });
        if (communityId) params.set('communityId', communityId.toString());
        window.location.href = `${Config.accounts.url}/login?${params.toString()}`;
    }

    async logout() {
        const params = new URLSearchParams({
            redirectUri: `${window.location.protocol}//${window.location.host}/logout`,
        });
        window.location.href = `${Config.accounts.url}/logout?${params.toString()}`;
    }

    async loginUser(token: string) {
        localStorage.setItem(accessTokenKey, token);
        const response = await apiService.get(`${Config.api.backend.urlV2}/user`);
        useUserStore().setUser(response.data.user);
    }

    async logoutUser() {
        localStorage.removeItem(accessTokenKey);
        useUserStore().setUser(null);
    }

    async initUser() {
        const accessToken = localStorage.getItem(accessTokenKey);
        if (accessToken) {
            const response = await apiService.get(`${Config.api.backend.urlV2}/user`);
            useUserStore().setUser(response.data.user);
        }
    }

    async setTermsAndEmailConsent(termsApproval: number, emailConsent: number) {
        await apiService.post(`${Config.api.backend.urlV2}/user/:set-consents`, {
            terms_approval: termsApproval,
            email_consent: emailConsent,
        });
        const userStore = useUserStore();
        userStore.setConsentEmail(emailConsent === 1)
        userStore.setTermsApproval(termsApproval === 1);
        userStore.setCommunityMailingListMember(emailConsent === 1);
    }
}

export const authService = new AuthService();
