<script setup lang="ts">
import { computed, ref, onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";

import BaseContextBox from "@/components/base/BaseContextBox.vue";
import BaseButtonToggle from "@/components/base/BaseButtonToggle.vue";
import HomepageItemCard from "@/components/HomepageItemCard.vue";
import HomepageItemPrivateNoAccessDialog from "@/components/dialogs/HomepageItemPrivateNoAccessDialog.vue";

import { Consultation } from "@/store/consultation.interface";
import { useCommunityStore } from '@/store/communityStore';
import { useI18n } from "vue-i18n";
import { setLocale } from "@/plugins/i18n";
import { User, useUserStore } from "@/store/userStore";
import { getTranslation, removeTimestamp } from "@/utils/data-display-utils";
import { storeToRefs } from "pinia";
import { VueCookies } from "vue-cookies";
import { constant } from "@/constant"
import { HomepageItem } from "@/store/common.interface";
import { Project } from "@/store/project.interface";

const cookies = inject<VueCookies>('$cookies')

// STORE
const communityStore = useCommunityStore();
const userStore = useUserStore();
const { community } = storeToRefs(communityStore)

// CONST
const { t } = useI18n();
const router = useRouter();
const route = useRoute();

// REFS
const toggleStateView = ref('ongoingValue');
const homepageItemPrivateNoAccessDialogRef = ref(null);
const needLogin = ref(false);
const selectedItemNeedAuth = ref();

const language = route.params.lang;
if (language && community.value.languageEnabled.includes(language)) {
    cookies.set('locale', language)
    setLocale(language);
}

// COMPUTED
const preLaunchState = computed(() => {
    return {
        name: "consultation.status.pre-launch",
        icon: "pending",
        quantity: filterHomepageItems(communityStore.preLaunchHomepageItems).length
    }
});

const ongoingState = computed(() => {
    return {
        name: "consultation.status.ongoing",
        icon: "lock_open",
        quantity: communityStore.ongoingHomepageItems.length
    }
});

const completedState = computed(() => {
    return {
        name: "consultation.status.completed",
        icon: "lock_clock",
        quantity: communityStore.completedHomepageItems.length
    }
});

const archivedState = computed(() => {
    return {
        name: "consultation.status.archived",
        icon: "archive",
        quantity: filterHomepageItems(communityStore.archivedHomepageItems).length
    }
});

const getHomepageItemState = computed(() => {
    let stateMessage = '';
    switch (toggleStateView.value) {
        case 'ongoingValue':
            stateMessage = t("consultation.no-ongoing");
            break;
        case 'completedValue':
            stateMessage = t("consultation.no-completed");
            break;
        case 'archivedValue':
            stateMessage = t("consultation.no-archived");
            break;
        case 'preLaunchValue':
            stateMessage = t("consultation.no-pre-launch");
            break;
        default:
            stateMessage = ''; // Handle default case
    }
    return stateMessage;
});

function filterHomepageItems(homepageItems: HomepageItem[]) {
    return homepageItems.filter(x => userStore.isAdmin() || userStore.isEditor(x.id));
}

const filteredHomepageItems = computed<HomepageItem[]>(() => {
    if (!(userStore.isAdmin() || userStore.isEditor())) {
        switch (toggleStateView.value) {
            case 'completedValue':
                return communityStore.completedHomepageItems;
            default:
                return communityStore.ongoingHomepageItems;
        }
    } else {
        switch (toggleStateView.value) {
            case 'completedValue':
                return communityStore.completedHomepageItems;
            case 'archivedValue':
                return filterHomepageItems(communityStore.archivedHomepageItems);
            case 'preLaunchValue':
                return filterHomepageItems(communityStore.preLaunchHomepageItems);
            default:
                return communityStore.ongoingHomepageItems;
        }
    }
});

// FUNCTIONS
onMounted(async () => {
    document.title = getTranslation(community.value, 'title');
    if (route.query?.projectId) {
        const projectId = parseInt(route.query.projectId as string);
        const project = communityStore.getProject(projectId);
        const result = communityStore.isUserAllowedAccessProject(project, userStore.user);
        if (!result.allowed) {
            selectedItemNeedAuth.value = project;
            openHomepageItemPrivateNoAccessPopup();
        }
    }
})

function enterToCard(item: HomepageItem) {
    if (item.itemType === constant.HomePageItem.Type.Consultation) {
        const userIsAllowed = communityStore.isUserAllowedAccessConsultation(item as Consultation, userStore.user as User)
        if (userIsAllowed.allowed) {
            router.push({
                name: "consultations",
                params: { slug: item.slug }
            });
        } else if (userIsAllowed.reason === 'NotAllowedByAdmin') {
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup();
        } else if (userIsAllowed.reason === 'login') {
            needLogin.value = true;
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup()
        }
    } else if (item.itemType === constant.HomePageItem.Type.Project) {
        const userIsAllowed = communityStore.isUserAllowedAccessProject(item as Project, userStore.user as User)
        if (userIsAllowed.allowed) {
            router.push({
                name: "project",
                params: { slug: item.slug }
            });
        } else if (userIsAllowed.reason === 'NotAllowedByAdmin') {
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup();
        } else if (userIsAllowed.reason === 'login') {
            needLogin.value = true;
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup()
        }
    }
}

function openHomepageItemPrivateNoAccessPopup() {
    homepageItemPrivateNoAccessDialogRef.value.openDialog();
}

</script>

<template>
    <div class="community">
        <div class="community__header">
            <img
                :src="removeTimestamp(communityStore.community.bannerFile.url)"
                alt="community-header"
                
            />
        </div>
        <div class="community__content">
            <div class="community__identification">
                <div class="community__logo">
                    <img
                        :src="removeTimestamp(communityStore.community.logoFile.url)"
                        alt="community-logo"
                    />
                </div>
                <div class="community__title">
                    {{ getTranslation(communityStore.community, 'title') }}
                </div>
                <BaseContextBox class="community__context">
                    <template v-slot:title>
                        <div>{{ $t("context.about") }}</div>
                    </template>
                    <template v-slot:content>
                        <div>
                            {{ getTranslation(communityStore.community, 'description') }}
                        </div>
                    </template>
                </BaseContextBox>
            </div>
            <div class="community__content-wrapper">
                <div class="community__consultation-cards">
                    <BaseButtonToggle
                        class="community__consultation-state"
                        v-model="toggleStateView"
                        :preLaunchState="preLaunchState"
                        :ongoingState="ongoingState"
                        :completedState="completedState"
                        :archivedState="archivedState"
                        :isAdmin="userStore.isAdmin() || userStore.isEditor()"
                    />
                    <div
                        v-if="filteredHomepageItems.length"
                        v-for="item in filteredHomepageItems"
                        class="community__consultation-card"
                        @click="enterToCard(item)"
                    >
                        <HomepageItemCard
                            :startDate="item.beginDate"
                            :endDate="item.endDate"
                            :description="getTranslation(item, 'title')"
                            :image="item.bannerFile ? item.bannerFile.url : removeTimestamp(communityStore.community.bannerFile.url)"
                            :key-information="item.summary"
                            :is-private="item.type === constant.Consultation.Type.Private || item.visibility === constant.Project.Type.Private"
                            :item-type="item.itemType"
                        />
                    </div>
                    <div class="community__consultation-card--empty" v-else>
                        {{ getHomepageItemState }}
                    </div>
                </div>
            </div>
        </div>
        <HomepageItemPrivateNoAccessDialog ref="homepageItemPrivateNoAccessDialogRef" :home-link="false"
                                           :item="selectedItemNeedAuth"
                                           :need-login="needLogin"/>
    </div>
</template>

<style lang="scss">
.community {
    &__header {
        max-height: 360px;

        img {
            width: 100%;
            height: 100%;
            max-height: 360px;
        }
    }

    &__content {
        padding-top: $space-xl;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: $space-md;
    }

    &__context {
        width: 730px;
    }

    &__identification {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-md;
    }

    &__logo {
        width: $space-2xl;
        height: $space-2xl;
        border-radius: 50%;
        border: 1px solid $color-primary-12;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-neutral-white;
        overflow: hidden;

        img {
            max-width: 80%;
            max-height: 80%;
        }
    }

    &__title {
        font-weight: bold;
        font-size: $h3;
        max-width: 580px;
        text-align: center;
        display: flex;
        justify-self: center;
    }

    &__consultation-state {
        margin: $space-md;
    }

    &__consultation-cards {
        min-height: 250px;
        display: flex;
        flex-direction: column;
        margin: $space-md auto $space-2xl auto;
        max-width: 760px;
        width: 100%;
        gap: 20px;
    }

    &__consultation-card {
        background-color: $color-neutral-white;
        border-radius: 8px;
        margin: 0 $space-md;

        &--empty {
            margin: $space-md;
            text-align: center;
            background: $color-neutral-grey-200;
            border-radius: 8px;
            padding: 32px;
            color: $color-neutral-grey-400-50;
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .community {

        &__consultation-state {
            margin: $space-md auto;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .community {

        &__consultation-state {
            margin: $space-md;
        }

        &__consultation-cards {
            max-width: auto;
        }

    }
}

@media screen and (max-width: $breakpoint-sm) {
    .community {

        .key-infos {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            &__info.is-title {
                width: 35%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: inherit;
        }

        &__identification {
            justify-content: center;
            max-width: 760px;
            margin: $space-md;
        }

        &__context {
            width: 100%;
        }

        &__consultation-cards {
            margin: $space-lg $space-md;
            gap: $space-md;
        }

        &__consultation-card {
            margin: 0;
        }

        &__consultation-state {
            width: 100%;
            margin: 0;
        }

    }
}
</style>
