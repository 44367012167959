<script setup lang="ts">

import { useI18n } from "vue-i18n";
import { useCommunityStore } from "@/store/communityStore";
import { currentLocale } from "@/plugins/i18n";
import { intlFormat } from "date-fns";

import SocialNetworks from "./SocialNetworks.vue";
import KeyInfo from "@/components/KeyInfo.vue";
import { constant } from "@/constant";


// PROPS
const props = defineProps<{
    startDate?: Date | null;
    endDate?: Date | null;
    description: string;
    image?: string;
    keyInformation?: object;
    isPrivate?: boolean;
    itemType: number;
}>();


// CONST
const { t } = useI18n();
const communityStore = useCommunityStore();


// FUNCTIONS
function formatDate(date: Date) {
    return intlFormat(date, { dateStyle: 'long'}, { locale: currentLocale() });
}

function getTranslationByItemType(key: string): string {
    const itemType = (() => {
        switch (props.itemType) {
            case constant.HomePageItem.Type.Project:
                return 'project';
            case constant.HomePageItem.Type.Consultation:
            default:
                return 'consultation';
        }
    })();
    return t(`dialog.homepageItemPrivateNoAccessDialog.${itemType}.${key}`);
}
</script>

<template>
    <div class="homepage-card">
        <img
            v-if="props.image"
            class="homepage-card__image"
            :src="props.image"
            alt="card-image"
        />
        <div class="homepage-card__content">
            <div class="homepage-card__content-private" v-if="props.isPrivate">
                <q-icon name="o_mail_lock"/>
               {{ getTranslationByItemType('private.title') }}
            </div>
            <div class="homepage-card__title-section">
				<span class="homepage-card__title">
                    <h1>{{ props.description }}</h1>
				</span>
            </div>
            
            <div v-if="props.startDate && props.endDate" class="homepage-card__date">
                {{formatDate(props.startDate) }} - {{formatDate(props.endDate) }}
            </div>
            <div v-else-if="props.endDate" class="homepage-card__date">
                {{ t('consultation.end-date') }}: {{formatDate(props.endDate) }}
            </div>
            <div class="homepage-card__infos">
                <div class="homepage-card__key-infos">
                    <KeyInfo
                        type="consultation"
                        isSmall
                        :key-information="props.keyInformation"
                    />
                </div>
                <q-chip square icon="lock" :label="getTranslationByItemType('private.tag')" v-if="props.isPrivate"/>
            </div>
            <SocialNetworks :community="communityStore.community"/>
        </div>
    </div>
</template>

<style lang="scss">
.homepage-card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 200ms ease;

    &:hover {
        box-shadow: 0 6px 10px 0 rgba($color-neutral-grey-400-50, 0.2);
        // TODO: Improve outline transition, for now, let's only use a shadow
        transition: all 200ms ease;
    }

    &__infos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        padding: $space-md;
        width: 100%;

        &-private {
            display: flex;
            gap: $space-sm;
            align-items: center;
            font-size: $sub-header;

            .q-icon {
                font-size: $small-title;
            }
        }
    }

    &__tags {
        display: flex;
        gap: $space-sm;
    }

    &__title {
        align-items: center;
        line-height: 100%;
        margin-top: 0px;
        margin-bottom: 0px;

        &-section {
            display: flex;
            align-items: center;
            padding: 0 0 $space-sm 0;
        }
    }

    &__date {
        color: $color-neutral-grey-400-50;
    }

    &__image {
        width: 100%;
        max-height: 162px;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
    }
}
</style>
