import { LanguageTranslation } from "@/store/language.interface";

export interface DTOContext{
    id: number;
    parent: number;
    parent_id: number;
    type: number;
    order_num: number;
    title: LanguageTranslation;
    content: LanguageTranslation | null;
    image: LanguageTranslation | null;
}

export interface Context {
    id: number;
    parent: number;
    parent_id: number;
    type: number;
    order_num: number;
    title: LanguageTranslation;
    content: LanguageTranslation | null;
    image: LanguageTranslation | null;
}

export function parseContext(dtoContext: DTOContext ): Context {
    return {
        id: dtoContext.id,
        parent: dtoContext.parent,
        parent_id: dtoContext.parent_id,
        type: dtoContext.type,
        order_num: dtoContext.order_num,
        title: dtoContext.title,
        content: dtoContext.content,
        image: dtoContext.image
    };
}
