import { Config } from '@/config';
import apiService from "@/services/api.service";

import { useCommunityStore } from '@/store/communityStore';
import { DTOFrequentlyAskedQuestion } from "@/store/faq.interface";


class FrequentlyAskedQuestionService {
    async getModuleFaqs(projectId: number, moduleId: number): Promise<DTOFrequentlyAskedQuestion[]> {
        const response = await apiService.get(
          `${Config.api.backend.urlV2}/project-modules/${moduleId}/frequently-asked-questions`,
        );
        if (response.data) {
          const communityStore = useCommunityStore();
          communityStore.setProjectModuleFaqs(response.data, moduleId, projectId);
        }
        return response.data;
    };
}

export const frequentlyAskedQuestionService = new FrequentlyAskedQuestionService();
