<script setup lang="ts">

import { computed, ref } from "vue";
import BaseButton from "@/components/base/BaseButton.vue";
import Footer from "@/components/layout/Footer.vue";
import LocaleSelect from "../LocaleSelect.vue";

import { useCommunityStore } from "@/store/communityStore";
import { getTranslation } from "@/utils/data-display-utils";
import { useUserStore } from "@/store/userStore";
import { Consultation } from "@/store/consultation.interface";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { paths } from "@/utils/paths";
import { storeToRefs } from "pinia";


// EMITS
const emit = defineEmits<{
    (e: "openLoginDialog"): void;
}>();

// STORE
const communityStore = useCommunityStore();
const userStore = useUserStore();
const { toggleStateView } = storeToRefs(communityStore)

const props = defineProps<{
    languageEnabled: string[]
}>();


// CONST
const router = useRouter();
const { t } = useI18n();


// REFS
const isMenuOpen = ref<boolean>(false)


// COMPUTED
const displayedIcon = computed(() => {
    return isMenuOpen.value ? 'close' : 'menu'
})


// FUNCTIONS
function enterToCard(consultation: Consultation) {
    new Date(consultation.createdDate).getTime()
    router.push({
        name: "consultations",
        params: { id: consultation.consultationId }
    });
    isMenuOpen.value = false
}

function showClosedConsultations() {
    toggleStateView.value = 'closedValue';
    router.push(paths.root)
    isMenuOpen.value = false
}

function goToProfilePage() {
    router.push(paths.profile);
    isMenuOpen.value = false
}

function logout() {
    router.push(paths.root);
    userStore.logOutUser();
    isMenuOpen.value = false
}


</script>

<template>
    <q-btn unelevated>
        <q-icon :name="displayedIcon"/>
        <q-menu
            class="mobile-menu"
            v-model="isMenuOpen"
            @before-show="isMenuOpen= true"
            @before-hide="isMenuOpen= false"
        >
            <div class="mobile-menu">
                <div class="lang q-mb-md">
                    <LocaleSelect class="" v-if="languageEnabled.length>1" :language-enabled="languageEnabled"/>
                </div>
                <div class="mobile-menu__main">
                    <div>
                        {{ $t("home-page") }} - {{ getTranslation(communityStore.community, 'title') }}
                    </div>

                </div>

                <q-separator/>
                <div class="mobile-menu__about-title"> {{ $t('about-us') }}</div>

                <div class="mobile-menu__about">
                    <div class="about">{{
                            $t("about.cocoriko-platform", { client: getTranslation(communityStore.community, 'title') })
                        }}
                    </div>
                    <router-link class="header__navigation--item" :to="paths.about">
                        <base-button
                            class="mobile-menu__button"
                            primary
                            is-small
                            :text="$t('about-us')"
                        />
                    </router-link>
                </div>

                <q-separator/>
                <div class="mobile-menu__profile">
                    <div class="mobile-menu__profile-title">
                        {{ $t('profile.my-account') }}
                    </div>
                    <div class="mobile-menu__information" v-if="userStore.isUserLogged">
                        <div class="mobile-menu__information--connected">
                            <div class="mobile-menu__information--username"> {{ userStore.getUserName }}</div>
                        </div>
                        <div class="mobile-menu__information--action" @click="goToProfilePage()">{{
                                $t("account.my-profile")
                            }}
                        </div>
                        <div class="mobile-menu__information--action" @click="logout()">
                            {{ $t("account.logout") }}
                        </div>
                    </div>
                    <base-button
                        v-else
                        class="mobile-menu__button"
                        primary
                        is-small
                        :text="$t('account.login')"
                        @click="emit('openLoginDialog')"
                    />
                </div>
            </div>
            <Footer class="mobile-menu__footer"/>
        </q-menu>
    </q-btn>
</template>

<style lang="scss">
.mobile-menu {
    font-size: $body-text;

    .lang {
        display: flex;
        justify-content: end
    }

    .title {
        font-weight: bold;
    }

    &__about {

        &-title {
            font-weight: bold;
            margin: $space-sm 0;
        }
    }

    &__profile {

        &-title {
            font-weight: bold;
            margin: $space-sm 0;
        }
    }

    &__main {
        background: $color-primary-12;
        color: $color-primary;
        margin-bottom: $space-md;
        font-size: $small-title;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__opened, &__closed {
        font-size: 12px;
        color: $color-primary;

        &--list {
            padding-top: $space-md;

            > div {
                padding-bottom: $space-md;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &__button {
        margin: $space-md 0;
    }

    overflow: hidden;

    &.q-position-engine {
        left: 0 !important;
        top: 56px !important;
        height: 100vh;
        width: 100vw;
        padding: 32px;
    }

    .q-separator {
        margin: $space-md 0;
    }

    &.q-menu {
        width: 100% !important;
        height: 100% !important;
        max-width: none !important;
        max-height: none !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__list {

    }

    &__information {
        background: $color-neutral-grey-100;
        border-radius: 8px;
        padding: $space-md;
        font-size: 16px;

        &--connected {
            display: flex;
            align-items: center;

        }

        &--username {
            margin-left: $space-md;
        }

        &--action {
            padding-top: $space-md;
        }
    }

}

</style>