<script setup lang="ts">

import { computed, onMounted, ref } from "vue";
import { getTranslation } from "@/utils/data-display-utils";
import { Module } from '@/store/module.interface';
import { useCommunityStore } from "@/store/communityStore";
import { contextService } from "@/services/context.service";
import { Project } from "@/store/project.interface";
import BaseMediaCard from "../base/BaseMediaCard.vue";
import { Context } from "@/store/context.interface";


const contexts = ref<Context[]>();

// CONST
const communityStore = useCommunityStore();


// PROPS
const props = defineProps<{
    module: Module;
}>();


// COMPUTED
const project = computed<Project>(() => {
    return communityStore.community.projects.find(project => project.id == props.module.project_id);
})


// LIFECYCLE
onMounted(async () => {
    contexts.value = await contextService.getModuleContext(props.module.id, project.value.id)
})


</script>

<template>
    <div class="attachments-module-wrapper">
        <div class="attachments-module">
            <div class="attachments-module__title">
                <h3>{{ getTranslation(props.module, 'title') }}</h3>
            </div>
            <div class="attachments-module__list">
                <q-card v-for="context in contexts" :key="context.id">
                    <BaseMediaCard
                        :type="context.type"
                        :title="getTranslation(context, 'title')"
                        :content="getTranslation(context, 'content')"
                    />
                </q-card>
           </div>
        </div>
    </div>
</template>

<style lang="scss">
.attachments-module-wrapper {
    width: 100%;
    margin-bottom: $space-md;

    .highlight {
        background-color: #cedbe4;
        border-radius: 8px;
        padding: $space-lg;
    }

    .attachments-module {
        display: flex;
        flex-direction: column;

        &__title {
            margin: $space-md 0;
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: $space-sm;

            &:hover {
                box-shadow: 0 6px 10px 0 rgba($color-neutral-grey-400-50, 0.2);
                transition: all 200ms ease;
            }

            .q-card {
                box-shadow: none;
                background-color: $color-neutral-white;
                padding: $space-sm;
                border-radius: 8px;
                cursor: pointer;

                .base-media {
                    padding: 0;
                }
            }
        }
    }
}
</style>
