export const Config = {
    api: {
        backend: {
            url: `${window._env_.API_URL}/api/v1`,
            urlAuth: `${window._env_.API_URL}`,
            urlV2: `${window._env_.API_URL}/api/v2`,
        },
    },
    admin: {
        url: `${window._env_.ADMIN_URL}`
    },
    accounts: {
        url: window._env_.ACCOUNTS_URL
    },
    mapbox: {
        accessToken: window._env_.MAPBOX_ACCESSTOKEN
    }
};

export const isProduction = () => {
    return Config.env === 'production';
};

export const isDevelopment = () => {
    return Config.env !== 'production';
};
