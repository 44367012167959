import { LanguageTranslation } from "./language.interface";

export interface DTOFrequentlyAskedQuestion {
    id: number;
    project_module_id: number;
    question: LanguageTranslation;
    answer: LanguageTranslation;
}

export interface FrequentlyAskedQuestion {
    id: number;
    project_module_id: number;
    question: LanguageTranslation;
    answer: LanguageTranslation;
}

export function parseFrequentlyAskedQuestion(dto: DTOFrequentlyAskedQuestion): FrequentlyAskedQuestion {
    return {
        id: dto.id,
        project_module_id: dto.project_module_id,
        question: dto.question,
        answer: dto.answer
    };
}
